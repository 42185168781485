import React, { Component } from 'react';

import './../App.css';

class Footer extends Component {
  render() {
    return (
      
        <footer className="App-footer">
          <p>Copyright © 2019. Nayelo</p>
        </footer>
      
    );
  }
}

export default Footer;
